<template>
  <div>
    <table-spinner :loading="loading"></table-spinner>
    <div v-if="!loading && subscription">
      <b-link
        v-if="this.$route.name === 'profile-subscription'"
        :to="{ name: 'profile', params: { tabName: 'tabName' } }"
        class="mb-1 ml-1"
        ><feather-icon icon="ArrowLeftIcon" size="20"></feather-icon>
        Back to subscriptions
      </b-link>
      <div class="d-flex ml-1 mt-1">
        <feather-icon icon="ShoppingBagIcon" size="19" />
        <h4 class="mb-0 ml-0">Subscription information</h4>
      </div>
      <div class="mt-1 ml-1">
        <b-row>
          <b-col cols="12" sm="4" md="3" lg="4">
            <b-form-group label="Subscription ID">
              <h5>{{ subscription.subscription_id }}</h5>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="2" md="3" lg="4"
            ><b-form-group label="User email">
              <b-link
                v-if="subscription.user && authUser.is_admin"
                :to="{ name: 'user', params: { id: subscription.user.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ userEmail }}
              </b-link>
              <template v-else>{{ userEmail }}</template>
              <span v-if="!subscription.user">-</span>
            </b-form-group></b-col
          >
        </b-row>
      </div>
      <transaction-list
        v-if="subscription.transactions"
        :data="subscription.transactions"
        :prevField="prevField"
        :filter="null"
      ></transaction-list>
    </div>
  </div>
</template>

<script>
import { BCol, BRow, BFormGroup, BLink } from "bootstrap-vue";
import { fetchSubscription } from "@/services/api/admin";
import { apiErrorHandler, changeTextColor } from "@/services/utils/util";
import TransactionList from "@/layouts/components/transaction/TransactionList.vue";
import TableSpinner from "@/layouts/components/TableSpinner.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    BFormGroup,
    BCol,
    BRow,
    BLink,
    TransactionList,
    TableSpinner,
    FeatherIcon,
  },
  data() {
    return {
      prevField: [{ key: "payment_id", label: "Payment ID" }],
      subscription: null,
      loading: false,
    };
  },
  computed: {
    authUser() {
      return this.$store.getters["auth/authUser"];
    },
    userEmail() {
      return this.subscription.user?.email;
    },
  },
  mounted() {
    this.onFetchSubscriptionData();
  },
  created() {
    this.$watch(
      () => this.$route.params.id,
      () => this.onFetchSubscriptionData()
    );
  },
  methods: {
    async onFetchSubscriptionData() {
      this.loading = true;
      try {
        this.subscription = await fetchSubscription(
          this.$route.params.subscription_id
        );
      } catch (e) {
        apiErrorHandler(e);
      } finally {
        this.loading = false;
      }
    },
    changeColor(item) {
      return changeTextColor(item);
    },
  },
};
</script>

<style></style>
